import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

const BreadcrumbOne = ({title, bg}) => {
    return (
        <div
            className="breadcrumb-area pb--100 breadcrumb-title-bar breadcrumb-title-white bg_color--black"
            style={{backgroundImage: `url(${bg})`}}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="breadcrumb-inner text-center">
                            <h2 className="heading">{title}</h2>
                            <div className="breadcrumb-inside" style={{paddingTop: '24px'}}>
                                {/* <ul className="core-breadcrumb">
                                    <li><a href={`${process.env.PUBLIC_URL + "/"}`}>Home</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL + "/elements"}`}>Elements</a></li>
                                    <li className="current">{title}</li>
                                </ul> */}
                                <span className='white'>
                                    Te ayudamos a diseñarla, a desarrollarla y a impulsarla.
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BreadcrumbOne;