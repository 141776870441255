import React, {Fragment, useRef} from 'react';
import {Helmet} from "react-helmet";
import brook from "data/brook.json";
import { MessengerChat } from 'react-messenger-chat-plugin';

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/SideHeader";
import SliderMinimalAgency from "container/slider/SliderMinimalAgency";
import Portfolios from "container/portfolio/homes/PortfolioOne";
// import ClientLogo from "container/client-logo/homes/ClientLogoThree";
import PopupMenu from "components/header/elements/popup";
import Footer from "container/footer/FooterFour";
import Services from "container/service/ServiceOne";
import FunFact from "container/funfact/FunfactOne";

const HomeMinimalAgency = () => {
    const portfolioElement = useRef(null);
    return (
        <Fragment>
            <Helmet>
                <title>{`Pentcloud || ${brook.title}`}</title>
            </Helmet>
            <LayoutDefault className="flex-container fluid-height template-color-23 template-font-4" revealFooter={false} refs={
                    {
                        '/': portfolioElement
                    }
            }>
                <Header/>
                <PopupMenu refs={
                    {
                        '/': portfolioElement
                    }
                }/>
                <div className='page-content'>
                    <SliderMinimalAgency/>
                    <Services/>
                    <FunFact/>
                    <Portfolios ref={portfolioElement}/>
                    {/* <ClientLogo/> */}
                    <Footer/>
                    <MessengerChat
                        pageId='259328580884571'
                        language='es_MX'
                        themeColor={'#0000FF'}
                        height={24}
                        loggedInGreeting='Hola!'
                        loggedOutGreeting='¡Hola, cuéntanos quién eres!'
                        autoExpand={true}
                        debugMode={false}
                        onMessengerShow={() => {console.log('onMessengerShow')}}
                        onMessengerHide={() => {console.log('onMessengerHide')}}
                        onMessengerDialogShow={() => {console.log('onMessengerDialogShow')}}
                        onMessengerDialogHide={() => {console.log('onMessengerDialogHide')}}
                    />
                </div>
            </LayoutDefault>
        </Fragment>
    );
};

export default HomeMinimalAgency;