import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Thumbnail from "components/about-us/thumbnail/AboutThumbOne";
import errorImg from "assets/img/icons/image_404.png";
import Button from "components/button";

const Error404 = () => {
    return (
        <div className="error-not-found">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="error-inner text-center">
                            <Thumbnail thumb={errorImg} className="mb--55 image text-center"/>
                            <h3 className="heading heading-h3 text-white">:(</h3>
                            <div className="error-text mt--20">
                                <p className="text-white">
                                    No encontramos nada en esta ubicación, puedes regresar o ir al inicio.
                                </p>

                                <div className="error-button-group mt--40">
                                    <Button
                                        type="button"
                                        btnText="Regresar"
                                        btnStyle="rounded"
                                        size="sd"
                                        color="theme"
                                        onClick={()=> window.history.back()}
                                    />

                                    <Button
                                        type="link"
                                        btnText="Inicio"
                                        btnLink="/"
                                        btnStyle="rounded"
                                        size="sd"
                                        color="white"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Error404;
