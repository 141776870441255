import React, {useState} from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const FunfactItemOne = ({number, title, content, className}) => {
    const [appear, setAppear] = useState(false);

    const visibleHandler = (isVisible) => {
        isVisible && setAppear(true);
    };
    return (
        // <div className={`number-wrap wow move-up ${className}`}>
        <div className={`number-wrap ${className}`}>
            <VisibilitySensor onChange={(e) => visibleHandler(e)}>
                <span className="count creative-color">
                    +<CountUp start={appear ? 0 : null} end={number}/>
                </span>
            </VisibilitySensor>
            <div className="content">
                {title && <h6 style={{marginBottom: '1rem'}}>{title}</h6>}
                <p style={{margin: 0, color: 'white'}}>{content}</p>
            </div>
        </div>
    );
};

export default FunfactItemOne;
