import React from 'react';
import {Link} from "react-router-dom";

const PortfolioItemOne = ({className, thumb, title, route, color}) => {
    // console.log(color);
    return (
        <Link to={`${process.env.PUBLIC_URL + (route || '/')}`}>
            <div className={`${className} portfolio portfolio_style--1`}>
                <div className="thumb">
                    <img src={thumb} alt={title}/>
                </div>
                <div className="port-overlay-info" style={{
                    backgroundColor: `${color || '#CCCCCC'}`
                }}>
                    <div className="hover-action">
                        <h3 className="post-overlay-title">{title}</h3>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default PortfolioItemOne;
