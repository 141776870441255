import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import Input from "components/input";
import { LineWave } from  'react-loader-spinner'

const Form = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState(false);
    const [msg, setMsg] = useState('')
    
    const handleChange = (e, setState) => {
        setState(e.target.value);
    }

    
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = async (e) => {
        setStatus(true)
        setMsg('')
        e.preventDefault();
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha();
        if (!token){return}

        let data = { email, name, comment, extras: {token, site: 'pentcloud.com'}, token, from: "pentcloud_landing_2022" }

        // let promiseTest = new Promise((resolve, reject) => {
        //     setTimeout(function(){
        //         resolve("¡Éxito!");
        //     }, 250);
        // });
        // promiseTest

        fetch("https://pentcloud-website-backend.vercel.app/public/v1/contacts", {
            method: "POST",
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify(data)
        })
        
        .then((res) => res.json())
        .then((d) => {
            setMsg('Gracias por escribirnos, en breve uno de nuestros agentes te estará contactando.')
            // alert(msg); //--> dynamically set your notification state via the server
            setStatus(true)
        }, (e) =>{
            setMsg('Hubo un error, por favor verifica tus datos')
            // alert(msg); //--> dynamically set your notification state via the server
            setStatus(false)
        });
    }

    return (
        <div className="contact-form">
            <form onSubmit={handleReCaptchaVerify}>
                <Row>
                    <Col xs={12}>
                        <Input
                            id={'name'}
                            name={'name'}
                            type={'text'}
                            placeholder={'Nombre'}
                            required={true}
                            onChange={e => handleChange(e, setName)}
                        />
                    </Col>

                    <Col xs={12} className="mt--30">
                        <Input
                            id={'email'}
                            name={'email'}
                            type={'text'}
                            placeholder={'Email'}
                            required={true}
                            onChange={e => handleChange(e, setEmail)}
                        />
                    </Col>

                    {/* <Col xs={12} className="mt--30">
                        <Input
                            id={'phone_number'}
                            name={'phone_number'}
                            type={'text'}
                            placeholder={'Phone Number'}
                            required={false}
                        />
                    </Col> */}

                    <Col xs={12} className="mt--30">
                        <Input
                            id={'comment'}
                            name={'comment'}
                            type={'textarea'}
                            placeholder={'¿Cómo podemos ayudarte?'}
                            required={true}
                            onChange={e => handleChange(e, setComment)}
                        />
                    </Col>

                    {(status && msg=='') && <div style={{textAlign:'center', paddingTop: '24px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <LineWave
                            color="blue"
                            height={110}
                            width={110}
                            ariaLabel="loading..."
                        />
                    </div> }

                    {!status && <Col xs={12} className="mt--30 text-center">
                        <Input
                            id={'submit_btn'}
                            name={'submit_btn'}
                            type={'submit'}
                            value={'Enviar mensaje'}
                            className=''
                        />
                    </Col>}
                    <br />
                    {(status || msg != '') && <div className='white' style={{textAlign:'center', paddingTop: '24px', width: '100%'}}>
                        {msg}
                    </div> }
                    
                    
                </Row>
            </form>
        </div>
    )
}

const ContactFormOne = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
            <Form />
        </GoogleReCaptchaProvider>
    );
};

export default ContactFormOne;
