import React, { forwardRef } from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SectionTitleTwo from "components/section-title/SectionTitleTwo";
import portfolios from '../../../data/portfolio/portfolio-one.json'
import PortfolioItem from "components/portfolio/PortfolioItemOne";

const PortfolioOne = forwardRef((props, ref) => {
    return (
        <div className="bk-portfolio-area" ref={ref}>
            <div className="black-bg-wrapper bg_color--3 section-pt-xl pb--240">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <SectionTitleTwo
                                className={'white-text'}
                                center={true}
                                // title={"PORTFOLIO"}
                                // route={portfolios[].route}
                                heading={"Le damos vida a tu idea."}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="black-bg-wrapper bg_color--3 pl--150 pr--150 pl_lg--50 pr_lg--50 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
                <Row className={"row--0 space_dec--110"}>
                    <Col lg={6}>
                        <PortfolioItem
                            color={portfolios[0].color}
                            className={`bg_color--grey-3`}
                            thumb={'https://s3.amazonaws.com/symfonia.io/Content/Group_1_4_6e40758f39.jpg'}
                            title={[portfolios[0].title]}
                            route={portfolios[0].route}
                        />
                    </Col>

                    <Col lg={6}>
                        <Row className={"row--0"}>
                            <Col xs={12}>
                                <PortfolioItem
                                    color={portfolios[1].color}
                                    className={`bg_color--grey`}
                                    thumb={'https://s3.amazonaws.com/symfonia.io/Content/Group_58_3_d90e4d7867.jpg'}
                                    title={[portfolios[1].title]}
                                    route={portfolios[1].route}
                                />
                            </Col>

                            <Col md={6}>
                                <PortfolioItem
                                    color={portfolios[2].color}
                                    className={`bg_color--grey`}
                                    thumb={require('../../../assets/img/svg/portfolio-1/' + portfolios[2].thumb)}
                                    title={[portfolios[2].title]}
                                    route={portfolios[2].route}
                                />
                            </Col>

                            <Col md={6}>
                                <PortfolioItem
                                    color={portfolios[3].color}
                                    className={`bg_color--grey-2`}
                                    thumb={'https://s3.amazonaws.com/symfonia.io/Content/Group_50_320ec86cc5.jpg'}
                                    title={[portfolios[3].title]}
                                    route={portfolios[3].route}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
});

export default PortfolioOne;
