import React from 'react';
import {Container, Row, Col} from "react-bootstrap"
import {LightgalleryProvider} from 'react-lightgallery'
import "lightgallery.js/dist/css/lightgallery.css";
import galleryContent from '../../data/gallery/masonry.json'
import GalleryItemOne from "components/gallery/GalleryItemOne";

const GalleryTwo = ({pictures = null, id}) => {
    // console.log(pictures)
    // console.log(id)
    return (
        <div className="brook-gallery-area ptb--150 ptb-md--80 ptb-sm--60 ">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="gallery-wrapper masonry-grid row mt--n30">
                            {pictures && <LightgalleryProvider
                                lightgallerySettings={{
                                    download: false,
                                    selector: `.gallery-container-${id}`
                                }}
                            >
                                {pictures?.length < 1 && galleryContent.map(gallery=>(
                                    <GalleryItemOne
                                        key={gallery.id}
                                        group="group2"
                                        thumb={require('../../assets/img/gallery/gallery-1/' + gallery.thumb)}
                                        preview={require('../../assets/img/gallery/gallery-1/' + gallery.preview)}
                                        masonry={true}
                                    />
                                ))}
                                {pictures?.length > 0 && pictures.map((picture, i)=>(
                                        <GalleryItemOne
                                        key={i}
                                        group={`group${id}`}
                                        thumb={picture}
                                        preview={picture}
                                        masonry={true}
                                    />
                                ))}
                            </LightgalleryProvider>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GalleryTwo;
