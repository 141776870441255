import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderTwo";
import Footer from "container/footer/FooterFour";
import PortfolioDetails from "container/portfolio-details/ModernPortfolioDetails";
import { useLocation } from 'react-router-dom'
import projects from "data/projects.json";

const defaultProject = {
    "id": "default",
    "name": "Pentcloud",
    "date": "2015-01-01",
    "customer": "Pentcloud",
    "problem": "¿Tienes una idea que quieres impulsar?",
    "solution": "Te ayudamos a diseñarla, a desarrollarla y a impulsarla.",
    "categories": ["internet", "software", "marketing", "design"],
    "url": "//yourspacesuite.com",
    "pictures": null
}

const PortfolioDetailsModern = () => {
    const [route] = useLocation().pathname.split('/').slice(-1) || null
    const projectData = projects[route] || defaultProject
    
    //get previous property of object by property key
    var getItem = function(key, i) {
        var keys = Object.keys(projects)
        var index = keys.indexOf(key);
        return projects[keys[index+i]] || null
    }

    return (
        <Fragment>
            <Helmet>
                <title>{projectData.name || 'Pentcloud'} || Te ayudamos en tu estrategia hacia la transformación digital </title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <div className="page-content">
                    <PortfolioDetails data={projectData} prev={getItem(route, -1)} next={getItem(route, 1)} />
                    <Footer/>
                </div>
            </LayoutDefault>
        </Fragment>
    );
};

export default PortfolioDetailsModern;
