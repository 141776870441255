import React, {Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Button from "components/button";
import Breadcrumb from "components/breadcrumb/BreadcrumbTwo";
import {Link} from "react-router-dom"; 
import GalleryTwo from "container/gallery/GalleryTwo";

const ModernPortfolioDetails = ({data, prev, next}) => {

    // const NextArrow = ({className, onClick}) => {
    //     return (
    //         <button className={`slick-btn ${className}`} onClick={onClick}>
    //             <i className="fa fa-angle-right"/>
    //         </button>
    //     );
    // };

    // const PrevArrow = ({className, onClick}) => {
    //     return (
    //         <button className={`slick-btn ${className}`} onClick={onClick}>
    //             <i className="fa fa-angle-left"/>
    //         </button>
    //     );
    // };

    // const settings = {
    //     slidesToShow: 3,
    //     dots: true,
    //     arrows: true,
    //     nextArrow: <NextArrow/>,
    //     prevArrow: <PrevArrow/>,
    //     responsive: [
    //         {
    //             "breakpoint": 992,
    //             "settings": {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             "breakpoint": 576,
    //             "settings": {
    //                 slidesToShow: 1
    //             }
    //         }
    //     ]
    // };

    return (
        <Fragment>
            <Breadcrumb
                title={data.name}
                bgImg={data.mainPicture}
                bgColor={'#000'}
            />

            <div className="brook-portfolio-details bg_color--black ptb--150 ptb-md--80 ptb-sm--60">
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="portfolio-left bk-portfolio-details">
                                <div className="portfolio-main-info">
                                    <h3 className="heading heading-h3 line-height-1-42 white">Sobre el proyecto</h3>

                                    <div className="portfolio-details-list mt--60 mt_sm--30">
                                        {/* {portDetailsData.meta.map(item => ( */}
                                            <div className="details-list">
                                                <label className='white'>Fecha</label>
                                                <span>{data.date}</span>
                                            </div>
                                            <div className="details-list">
                                                <label className='white'>Cliente</label>
                                                <span>{data.customer}</span>
                                            </div>
                                            <div className="details-list">
                                                <label className='white'>Categorías</label>
                                                <span>{data.categories.join(', ')}</span>
                                            </div>
                                        {/* ))} */}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={7}>
                        <div className="portfolio-content mt_sm--60 mt_md--80">
                                <h6 className="heading heading-h6 white">¿Qué necesitaban?</h6>
                                <div className="desc mt--20">
                                    <p className="bk_pra justify-text-only white" dangerouslySetInnerHTML={{__html: data.problem}}/>

                                    {/* <div className="brk-btn-wrap mt--40 mt_sm--20">
                                        <Button
                                            type="link"
                                            size="sd"
                                            color="theme"
                                            btnStyle="rounded"
                                            btnText="Live Preview"
                                            btnLink="https://hasthemes.com"
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <br />
                            <div className="portfolio-content mt_sm--60 mt_md--80">
                                <h6 className="heading heading-h6 white">Cómo lo resolvimos?</h6>
                                <div className="desc mt--20">
                                    <p className="bk_pra justify-text-only white" dangerouslySetInnerHTML={{__html: data.solution}}/>

                                    <div className="brk-btn-wrap mt--40 mt_sm--20">
                                        <Button
                                            type="link"
                                            linkType={'external'}
                                            size="sd"
                                            color="theme"
                                            btnStyle="rounded"
                                            btnText="Conoce el proyecto"
                                            btnLink={data.url}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt--100 mt_md--80 mt_sm--60">
                        <Col xs={12}>
                            { data.pictures && data.pictures.length > 0 ? <GalleryTwo pictures={data.pictures} id={data.id} /> : ''}
                            {/* <SlickSlider
                                settings={settings}
                                classes="brook-element-carousel slick-gutter-15 slick-arrow-center slick-dots-bottom"
                            >
                                {data.pictures.map((sliderImage, key) => (
                                    <ImageSlider key={key} image={sliderImage}/>
                                ))}
                            </SlickSlider> */}
                        </Col>
                    </Row>

                    {/* <Row className="mt--150 mt_md--120 mt_sm--100">
                        <Col lg={12}>
                            <PopupVideo
                                isThumb={true}
                                thumb={videoThumb}
                            />
                        </Col>
                    </Row> */}

                    <Row className="mt--100 mt_md--80 mt_sm--60">
                        <Col lg={12}>
                            <div className="portfolio-nav-list">
                                <div className="portfolio-page prev">
                                    {prev &&<div className="inner">
                                        <a href={`${process.env.PUBLIC_URL}/projects/${prev.id}`}>
                                            <p>Anterior</p>
                                            <h3 className="heading heading-h3 white">{prev.name}</h3>
                                        </a>
                                    </div>}
                                </div>
                            <div className="portfolio-page next mt_sm--30">
                                {next && <div className="inner">
                                        <a href={`${process.env.PUBLIC_URL}/projects/${next.id}`}>
                                            <p>Siguiente</p>
                                            <h3 className="heading heading-h3 white">{next.name}</h3>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};

export default ModernPortfolioDetails;
