import React from 'react';

const Copyright = ({className}) => {
    return (
        <p className={className ? className : ''}>
            © {new Date().getFullYear()} Pentcloud. Made with <i className="fa fa-heart text-danger"/> by <a href="//pentcloud.com" target="_blank" rel="noopener noreferrer">Pentcloud.</a>
        </p>
    );
};

export default Copyright;
